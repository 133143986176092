
import { createRouter, createWebHistory } from 'vue-router';
import SendRedEnvelope from './views/SendRedEnvelope.vue';
import ReceiveRedEnvelope from './views/ReceiveRedEnvelope.vue';

// import Vue from 'vue';
// Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'SendRedEnvelope',
    component: SendRedEnvelope,
  },
  {
    path: '/receive',
    name: 'ReceiveRedEnvelope',
    component: ReceiveRedEnvelope,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
