<template>
<div class="red-envelope-container">
    <p class='title'>Receive Your Red Envelope</p>
    <p class="title">money: {{wealth}}</p>
    <p class='time' v-if="countdown > 0">红包有效期: <span class="red_font"> {{ countdown }} </span> 毫秒</p>
    <p class='time' v-else>红包已过期</p>
    <div class="status-box" v-if='statusB'>
        <p>Status: {{ status }}</p>
    </div>
    <button @click="receiveRedEnvelope" :disabled="loading" class="btn btn-primary">
        recieve
    </button>
</div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'ReceiveRedEnvelope',
    data() {
        return {
            status: "Not received",
            loading: false,
            countdown: "000",
            statusB: false,
            wealth: 0.0,
            inidata:''
        };
    },
    mounted() {
        this.telegram = window.Telegram.WebApp;
        this.telegram.ready();
        this.telegram.expand();// 解析telegram数据
        this.initData = this.telegram.initData;

        this.fetchCountdown();
        this.get_userinfo();

    },
    methods: {
        receiveRedEnvelope() {
            console.log('receive packet')
            //
            this.loading = true;
            this.statusB = true;

            // Simulate a request to the backend to receive the red envelope
            // 处理逻辑放后台
            axios.post('https://redpacket.app/miniapp/receive_red_envelope', {
                WebAppInitData: this.initData
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data.success) {
                    this.status = 'get ' + response.data.data.value + ' from packet';
                    this.get_userinfo();
                } else {
                    this.status = response.data.msg;
                }

            }).catch((error) => {
                console.error(error);
                this.status = "Error receiving red envelope.";
            })

        },
        startCountdown() {
            this.countdownInterval = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                } else {
                    this.isRedEnvelopeExpired = true; // 倒计时结束，红包失效
                }
            }, 1);
        },
        fetchCountdown() {
            axios.post('https://redpacket.app/miniapp/get_packetinfo', {
                WebAppInitData: this.initData,
            }).then((response) => {
                    if (response.data.success) {
                        if (response.data.data.countdown > 0) {

                            this.countdown = response.data.data.countdown;
                            this.startCountdown();

                            if (response.data.data.remaining <= 0) {
                                this.loading = true
                                this.status = '红包已经领完'
                            } else if (response.data.data.recieved) {
                                this.loading = true
                                this.status = '您已领取该红包'
                            }

                        } else {
                            this.loading = true
                            this.status = '红包已超时'
                        }} else {
                        this.loading = true
                        this.status = '无效红包'
                    }
                }

            )

        },
        get_userinfo() {
            axios.post('https://redpacket.app/miniapp/get_userinfo', {
                WebAppInitData: this.initData
            }).then((response) => {
                this.wealth = response.data.data.wealth;
            })
        },
    }
}
</script>

<style>
.red-envelope-container {
    @apply h-1/2 p-10 text-center
}

.title {
    @apply text-center mb-8 text-gray-800 text-sm text-blue-600/50
}

.time {
    @apply text-center mb-8
}

.status-box {
    @apply mb-8 p-2.5 border border-gray-300 bg-gray-100;
}

.red_font {
    @apply border-red-800 !important;
}

.btn {
    @apply w-full p-2.5 border-none rounded-md bg-blue-500 box-border px-4 py-2 text-base cursor-pointer disabled:bg-gray-300 disabled:cursor-not-allowed;
}


</style>
