<template>
  <div id="app" class="container">
    <div class="red-envelope" v-if="!show">
      <p class="title">Send your gift to friend</p>
      <p class="title">money: {{wealth}}</p>
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="amount">金额</label>
          <input type="number" id="amount" v-model="amount" step=0.01  required class="form-control" @blur="hideKeyboard"/>
        </div>
        <div class="form-group">
          <label for="quantity">数量</label>
          <input type="number" id="quantity" v-model="quantity" required class="form-control" @blur="hideKeyboard"/>
        </div>
        <button type="submit" class="btn btn-primary">生成红包</button>
      </form>
    </div>

    <div class="red-envelope" v-if="show">
      <p class="title link">红包链接: <a id="link" class="text-clip overflow-hidden" :href="redEnvelopeLink" taget='_blank'>{{ redEnvelopeLink }}</a></p>
      <button @click="copyLink" class="btn btn-secondary">复制链接</button>
      <button @click="shareLink" class="btn btn-secondary">分享链接</button>
      <button @click="goback" class="btn btn-secondary">再次创建</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      currency: "",
      amount: 0,
      quantity: 1,
      message: '',
      expiration: "",
      telegram: null,
      query: '',
      redEnvelopeLink: '123',
      show: false,
      wealth:0.0,
      inidata: ''
    };
  },
  mounted() {
    const param = this.$route.query.tgWebAppStartParam;
    console.log('params', param)
    // 接收红包
    if ((typeof param !== 'undefined') && (param !== 'send')) {
      this.$router.push({ name: 'ReceiveRedEnvelope' });
    }

    this.telegram = window.Telegram.WebApp;
    this.telegram.ready();
    this.telegram.expand();// 解析telegram数据
    this.inidata = this.telegram.initData
    

    this.get_userinfo();

  },
  methods: {
    get_userinfo() {
      axios.post('https://redpacket.app/miniapp/get_userinfo', {
        WebAppInitData: this.telegram.initData,
      }).then((response) => {
        this.wealth = response.data.wealth;
      })
    },
    sendData(data) {
      axios.post('https://redpacket.app/miniapp/sendRedpacket', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (response.data.status == 'success'){
          this.redEnvelopeLink = response.data.data.url;
          this.show = true;
          }else{
          alert(response.data.msg);
          }
      }).catch((error) => {
        console.error(error);
      })
    },

    submitForm() {
      

      if (this.amount > 0 && this.quantity > 0)
      {  this.sendData(
          {
            WebAppInitData: this.telegram.initData,
            amount: this.amount,
            quantity: this.quantity,
          }
        ); } else{
          alert('输入值不合理')
        }

      // this.telegram.close();
    },

    goback() {
      this.show = false;
      this.get_userinfo();
    },


    copyLink() {
      const link = this.redEnvelopeLink;
      navigator.clipboard.writeText(link).then(() => {
        console.log(link)
        alert("链接已复制到剪贴板");
      }).catch((error) => {
        console.error("复制失败:", error);
      });
    },

    shareLink() {
      console.log(' share link' )
      const link = this.redEnvelopeLink;
      const url = `https://t.me/share/url?url=${encodeURIComponent(link)}`;
      console.log(url)
      window.location.href = url;
    },
    hideKeyboard() {
      // Hide keyboard functionality for mobile devices
      if (document.activeElement) {
        document.activeElement.blur(); // Removes focus from the input field
      }
    }

  }

};
</script>

<style>
.container {
  @apply mx-auto box-border
}

.red-envelope {
  @apply bg-white p-10 rounded-lg shadow-md
}

.redEnvelopeLink {
  @apply bg-white p-40 rounded-lg shadow-md
}

.title {
  @apply text-center mb-8 text-gray-800 text-sm text-blue-600/50
}

.link {
  @apply text-left mb-8 text-gray-800 text-sm text-blue-600/50
}

.label {
  @apply block mb-1 text-gray-600
}

.form-control {
  @apply w-full p-2.5 border border-gray-50 rounded-md box-border;
}

.btn {
  @apply w-full p-2.5 border-none rounded-md bg-blue-500 text-white cursor-pointer hover:bg-blue-700 box-border;
}

.btn-primary {
  @apply bg-blue-500 hover:bg-blue-700;
}

.btn-secondary {
  @apply bg-gray-500 hover:bg-gray-700 mb-10
}

.form-group {
  @apply mb-10
}

.footer {
  @apply bg-gray-800 text-white text-center p-4
}
</style>
